<template>
  <div>
    <b-row v-if="theUser.id">
      <b-col cols="!2" md="3" class="px-4 mb-4">
        <h3 class="p-1">{{ theUser.profile.fname }} {{ theUser.profile.lname }}</h3>
        <div style="position: relative">
          <b-img
            :src="userImage(user) || 'https://img.icons8.com/bubbles/100/000000/user.png'"
            alt="User-Profile-Image"
            fluid
          ></b-img>
          <b-button
            v-if="theUser.profile.image"
            style="position: absolute; bottom: 0; left: 0"
            size="sm"
            variant="danger"
            @click="onRemoveProfileImage()"
            v-b-tooltip.hover
            title="Remove Image"
          >
            <b-icon-dash></b-icon-dash>
          </b-button>
        </div>
      </b-col>
      <b-col>
        <h1 class="ml-4">Update image:</h1>
        <MediaUpload :subDir="imageDirUserProfile" @uploaded="onImageUploaded" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { constants } from "@/shared/constants";
import MediaUpload from "@/components/MediaUpload";
const USER_DEFAULTS = {
  id: null,
  name: null,
  active: null,
  title: null,
  email: null,
  image: null,
  ext: "123",
};
export default {
  name: "UserProfileImageEdit",
  components: { MediaUpload },
  data() {
    return {
      userId: null,
      currentStatus: null,
      imageDirUserProfile: constants.IMAGE_DIR_USER_PROFILE,
    };
  },
  computed: {
    ...mapGetters(["user"]),
    theUser() {
      return { ...USER_DEFAULTS, ...this.user };
    },
    canEditImage() {
      if (this.currentUser.roles.includes("ROLE_ADMIN")) return true;
      else if (this.userId == this.currentUser.id) return true;
      else if (
        this.currentUser.roles.includes("ROLE_DIRECTOR") /* && @TODO check assigned */
      )
        return true;
      return false;
    },
  },
  methods: {
    ...mapActions(["getUser"]),
    onImageUploaded(data) {
      this.putUserProfileImage(data);
      this.$emit("updateUserProfileImage", data.id);
    },
    onRemoveProfileImage() {
      this.$bvModal.msgBoxConfirm("Remove your profile image?").then((confirm) => {
        if (!confirm) return;
        this.putUserProfileImage(null);
      });
    },
    async putUserProfileImage(imageData) {
      await this.$store.dispatch("putUser", {
        id: this.userId,
        profileImage: imageData ? imageData["@id"] : null,
      });
      await this.getUser(this.userId);
    },
  },
  watch: {
    "$route.params.user"(val) {
      this.getUser(val);
    },
  },
  async mounted() {
    this.userId = this.$route.params.user;
    await this.getUser(this.userId);
    if (!this.canEditImage) {
      this.$router.push("/");
    }
  },
};
</script>
